import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('SiteHeaderComponent', {
    attrs: {
      "header": "Artikelsuche"
    }
  }), _c(VContainer, {
    staticClass: "elevation-2 white mt-4 mb-10 pt-8",
    staticStyle: {
      "max-width": "800px"
    },
    attrs: {
      "d-flex": "",
      "justify-center": "",
      "flex-wrap": ""
    }
  }, [_c(VResponsive, [_c(VTextField, {
    staticClass: "mx-sm-4 mr-2",
    attrs: {
      "solo": "",
      "label": "Suche",
      "clearable": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchTrigger.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  })], 1), _c(VBtn, {
    staticClass: "mx-sm-4 py-6 px-8 primary",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.searchTrigger
    }
  }, [_vm._v("Suchen")]), _c('TimeFilter', {
    staticClass: "col-12"
  })], 1), _c(VDataTable, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dataTableVisible,
      expression: "dataTableVisible"
    }],
    key: "nr",
    staticClass: "elevation-1 mb-7 datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.results,
      "items-per-page": 10
    },
    on: {
      "click:row": _vm.openArticle
    },
    scopedSlots: _vm._u([{
      key: "item.image_base64",
      fn: function ({
        item
      }) {
        return [_c('img', {
          staticClass: "rounded pt-1",
          staticStyle: {
            "max-height": "70px"
          },
          attrs: {
            "src": item.image_base64,
            "alt": item.name
          }
        })];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten gefunden. ")];
      },
      proxy: true
    }])
  }), _vm.searchSuccessful && _vm.results.length == 0 ? _c('div', {
    staticClass: "text-center mb-8"
  }, [_c(VImg, {
    staticClass: "mx-auto mt-4 mb-8",
    attrs: {
      "src": require("@/assets/svg/undraw_empty_xct9.svg"),
      "max-width": "250px"
    }
  }), _vm._v(" Keine Daten gefunden. Bitte überprüfen Sie Ihre Eingabe. ")], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };