import "core-js/modules/es.array.push.js";
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    searchInput: '',
    searchSuccessful: false,
    results: [],
    controller: undefined,
    loading: false,
    headers: [{
      text: 'Artikelnummer',
      value: 'articleNumber'
    }, {
      text: 'Beschreibung',
      value: 'description'
    }, {
      text: 'Anzahl',
      value: 'amount'
    }, {
      text: 'Umsatz',
      value: 'revenue'
    }],
    showNotImplementedDialog: false
  }),
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    }),
    ...mapGetters(['searchAddress']),
    dataTableVisible() {
      if (this.results.length == 0) return false;
      return true;
    }
  },
  methods: {
    search(query) {
      if (query == undefined) return;
      this.loading = true;
      //Check if there are any previous pending requests
      if (typeof this.controller != typeof undefined) {
        this.controller.abort('Operation canceled due to new request.');
      }
      this.controller = new AbortController();
      axios.get(`/v1/statistics/customers/${this.lfdnr}/articles/search`, {
        params: {
          q: query,
          from: this.filterFromDate,
          to: this.filterToDate
        },
        signal: this.controller.signal
      }).then(response => {
        this.results = response.data;
        this.searchSuccessful = true;
        this.loading = false;
      }).catch(err => {
        console.log('error searching address: ' + err);
      });
    },
    searchTrigger() {
      this.$router.push({
        query: {
          ...this.$route.query,
          q: this.searchInput
        }
      });
      this.search(this.searchInput);
    },
    openArticle(item) {
      // Currently not yet implemented
      this.$router.push(`/articles/${item.articleNumber}`);
      console.log(item); // avoiding unues error
      return;

      // this.$router.push({
      //   name: 'statisticsArticle',
      //   params: { lfdnr: this.lfdnr, artNr: item.articleNumber },
      //   query: {
      //     from: this.filterFromDate,
      //     to: this.filterToDate
      //   }
      // })
    }
  },
  mounted() {
    if (this.$route.query['q'] != null) {
      this.searchInput = this.$route.query['q'];
      this.search(this.searchInput);
    }
  },
  watch: {
    searchInput() {
      this.searchTrigger();
    },
    filterFromDate: function () {
      this.searchTrigger();
    },
    filterToDate: function () {
      this.searchTrigger();
    }
  }
};