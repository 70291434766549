import "core-js/modules/es.array.push.js";
import { mapState, mapGetters } from 'vuex';
import DistributorService from '@/services/statistics/distributorService.js';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    searchInput: '',
    searchSuccessful: false,
    results: [],
    controller: undefined,
    loading: false,
    headers: [{
      text: 'Bild',
      align: 'start',
      sortable: false,
      value: 'image_base64'
    }, {
      text: 'Artikelnummer',
      value: 'nr'
    }, {
      text: 'Beschreibung',
      value: 'description'
    }, {
      text: 'Anfangsbestand (Stk.)',
      value: 'openingStock',
      align: 'end'
    }, {
      text: 'Einkäufe (Stk.)',
      value: 'purchases',
      align: 'end'
    }, {
      text: 'Verkäufe (Stk.)',
      value: 'sales',
      align: 'end'
    }, {
      text: 'Inventur (Stk.)',
      value: 'inventory',
      align: 'end'
    }, {
      text: 'Materialverbrauch (Stk.)',
      value: 'materialsInput',
      align: 'end'
    }, {
      text: 'Endbestand (Stk.)',
      value: 'finalStock',
      align: 'end'
    }]
  }),
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    }),
    ...mapGetters(['searchAddress']),
    dataTableVisible() {
      if (this.results.length == 0) return false;
      return true;
    }
  },
  methods: {
    search(query) {
      if (query == undefined) return;
      this.loading = true;
      // Check if there are any previous pending requests
      if (typeof this.controller != typeof undefined) {
        this.controller.abort('Operation canceled due to new request.');
      }

      // Create AbortController for current request
      this.controller = new AbortController();
      DistributorService.getSearchResults(this.lfdnr, {
        q: query,
        from: this.filterFromDate,
        to: this.filterToDate
      }, this.controller).then(response => {
        this.results = response;
        this.searchSuccessful = true;
        this.loading = false;
      }).catch(err => {
        console.log('error searching address: ' + err);
      });
    },
    searchTrigger() {
      this.$router.push({
        query: {
          ...this.$route.query,
          q: this.searchInput
        }
      });
      this.search(this.searchInput);
    },
    openArticle(item) {
      this.$router.push({
        name: 'statisticsArticle',
        params: {
          lfdnr: this.lfdnr,
          artNr: item.nr
        },
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        }
      });
    }
  },
  mounted() {
    if (this.$route.query['q'] != null) {
      this.searchInput = this.$route.query['q'];
      this.search(this.searchInput);
    }
  },
  watch: {
    searchInput() {
      this.searchTrigger();
    },
    filterFromDate: function () {
      this.searchTrigger();
    },
    filterToDate: function () {
      this.searchTrigger();
    }
  }
};